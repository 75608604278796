import { useMeasure } from "react-use";

import { Bar2 } from "./About/Bar";
import { Beam } from "./About/Decor/Beam";
import { Column } from "./About/Decor/Column";

export const Club = () => {
  const [ref, { height }] = useMeasure();

  return (
    <>
      <div
        style={{
          position: "relative",
          overflow: "hidden",
          zIndex: 5,
        }}
      >
        <Beam bg="#363636" bs1="0.3" bs2="0.3" />
        <div className="row" style={{ position: "relative", zIndex: 2 }}>
          <div className="d-none d-lg-block col-1 col-xl-2" />
          <div className="col-12 col-lg-10 col-xl-8 d-flex align-items-end justify-content-center">
            <div style={{ height: height }} className="d-none d-md-block">
              <Column bg="#363636" bs1="0.3" bs2="0.3" />
            </div>
            <div ref={ref} style={{ width: "100%", position: "relative" }}>
              <div style={{ height: 300 }}></div>
              <div className="neon-wrap neon-text">Whales Club</div>
              <div>
                <Bar2 />
              </div>
            </div>
            <div style={{ height: height }} className="d-none d-md-block">
              <Column bg="#363636" bs1="0.3" bs2="0.3" />
            </div>
          </div>
        </div>
        <div
          style={{
            transform: "rotate(180deg)",
            position: "relative",
            zIndex: 100,
          }}
        >
          <Beam bg="#363636" bs1="0.3" bs2="0.3" />
        </div>
      </div>
    </>
  );
};

// <img alt="" src="/images/barScene.png" className="club-img" />
