import { useMeasure } from "react-use";

export const Cinders = ({ top = true, jagged, turnTop }) => {
  const [ref, { width, height }] = useMeasure();
  const isSmall = width < 991;

  return (
    <div ref={ref} className="cinder-wall">
      {width && (
        <InnerCinders
          top={top}
          width={width}
          height={height}
          jagged={jagged}
          turnTop={turnTop}
          isSmall={isSmall}
        />
      )}
    </div>
  );
};

const InnerCinders = ({ top, width, height, jagged, isSmall, turnTop }) => {
  const count = isSmall ? 4 : 7;
  const bricks = [...new Array(count).fill({})];
  const brickWidth = width / (count - 1);
  const brickHeight = brickWidth / 3;
  const _rowCount = Math.ceil(height / brickHeight);
  const rowCount = jagged ? _rowCount - 1 : _rowCount;
  const rows = [...new Array(rowCount).fill({})];

  return (
    <>
      {top && <TopBricks turnTop={turnTop} />}
      {jagged && (
        <div className="d-flex">
          {topBricks?.map((b, idx) => (
            <div
              key={idx}
              style={{
                flexShrink: 0,
                width: brickWidth,
                height: brickHeight,
                ...b,
              }}
            >
              <div className="cinder" />
            </div>
          ))}
        </div>
      )}
      {rows?.map((r, id) => (
        <div
          key={id}
          className="d-flex"
          style={{
            transform:
              id % 2 !== 0 ? "" : `translate(-${brickWidth / 2}px, 0px)`,
          }}
        >
          {bricks?.map((b, idx) => (
            <div
              key={idx}
              style={{
                width: brickWidth,
                height: brickHeight,
                flexShrink: 0,
              }}
            >
              <div className="cinder" />
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

export const TopBricks = ({ turnTop }) => {
  const topBricks = [...new Array(6).fill({})];

  return (
    <div
      className="cinder-top shadow d-flex"
      style={{
        transform: turnTop ? "rotate(-0.75deg)" : "",
        marginBottom: turnTop ? -15 : 0,
      }}
    >
      {topBricks?.map((b, idx) => (
        <div
          key={idx}
          style={{
            width: "20%",
            height: "100%",
            flexShrink: 0,
          }}
        >
          <div className="cinder-top-brick" />
        </div>
      ))}
    </div>
  );
};

const topBricks = [
  { opacity: 0 },
  { opacity: 1, boxShadow: "0px -2px 5px rgba(0,0,0,.2)" },
  { opacity: 0 },
  { opacity: 1, boxShadow: "0px -2px 5px rgba(0,0,0,.2)" },
  { opacity: 1, boxShadow: "0px -2px 5px rgba(0,0,0,.2)" },
  { opacity: 0 },
  { opacity: 1, boxShadow: "0px -2px 5px rgba(0,0,0,.2)" },
  { opacity: 0 },
  { opacity: 0 },
];
