import React from "react";
import Highlight from "./basic/Highlight";

const items = [
  {
    title: "Jupiter",
    extras: [],
    link: "https://jup.ag/swap/SOL-WHC_9VLtWhS7Zope97RAJ1KBk2n8FHcNDu5QxqXWv1A3WMko",
    logo: "/images/jupiter-logo.png",
  },
  {
    title: "Phantom",
    extras: [],
    link: "https://phantom.app/download",
    logo: "/images/phantom-logo.png",
  },
];

const Cta = () => {
  return (
    <section>
      <div className="container d-flex align-items-center flex-column">
        <div id="buy" className="container section-container">
          <div
            className="d-flex align-items-center"
            style={{ flexDirection: "column" }}
          >
            <Highlight
              text="Get a Bag"
              className="mt-5"
              c2="#f5cb45"
              c1="#000"
            />
            <div
              className="section-header"
              style={{ textShadow: "1px 1px 2px rgba(0,0,0,.4)" }}
            >
              Buy $WHC
            </div>
          </div>
          <div className="row mt-3">
            <div className="d-none d-lg-block col-2" />
            {items?.map((item, key) => (
              <Item key={key} item={item} page={true} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

// <TreasureChest page={true} />

export default Cta;

const Item = ({ item }) => {
  const { title, logo, link } = item;

  return (
    <div className="col-12 col-md-6 col-lg-4 mb-4 mb-md-0">
      <div className="service-one__top">
        <div className="feature-content">
          <div className="mb-3">
            <a href={link} target="_blank" rel="noreferrer">
              <img alt=" " src={logo} style={{ width: 150 }} />
            </a>
          </div>
          <a
            href={link}
            target="_blank"
            rel="noreferrer"
            className="feature-header marker"
            style={{ color: "black" }}
          >
            {title}
          </a>
        </div>
      </div>
    </div>
  );
};
