import React, { useState, useContext, createContext } from "react";
import { useMeasure } from "react-use";

const AppContext = createContext();

export default function AppContextWrapper({ children }) {
  const [smallMenu, setSmallMenu] = useState(false);
  const [ref, { width }] = useMeasure();
  const fullWidth = width * 0.2 + width;
  const cinderHeight = fullWidth / 18;
  const contRef = ref;

  const appLocalState = {
    contRef,
    smallMenu,
    setSmallMenu,
    cinderHeight,
    pageWidth: width,
  };

  return (
    <AppContext.Provider value={appLocalState}>{children}</AppContext.Provider>
  );
}

export function useApp() {
  return useContext(AppContext);
}
