import React from "react";
import Highlight from "./basic/Highlight";
// import { useMeasure } from "react-use";
// import Drip from "./basic/Drip";

const Whaleonomics = () => {
  return (
    <section className="section-container" id="whaleonomics">
      <div className="text-center">
        <Highlight text="About $WHC" className="mt-5" c2="#f5cb45" c1="#000" />
        <div className="section-header">Whaleonomics</div>
      </div>

      <div className="container">
        <div className="whaleonomics-cont">
          {points?.map((p, idx) => (
            <div
              key={idx}
              className="mt-3"
              style={{ position: "relative", textAlign: "center" }}
            >
              <div className="token-title">{p?.title}</div>
              <div className="token-subtitle">{p?.sub}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Whaleonomics;

const points = [
  {
    title: "4M",
    sub: "burned",
    drips: [
      // { l: 30, h: 32, t: 80 },
      { l: 66, h: 40, t: 80 },
    ],
  },
  {
    title: "100M",
    sub: "total supply",
    drips: [
      { l: 40, h: 30, t: 95 },
      { l: 54, h: 52, t: 95 },
    ],
  },
  { title: "96M", sub: "circulation", drips: [] },
  { title: "4M", sub: "airdropped", drips: [] },
  { title: "0", sub: "team tokens", drips: [] },
];
