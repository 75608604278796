import React from "react";
import Header from "./Header";
import SmallMenu from "./SmallMenu";
import { useApp } from "../context";

const Page = ({ children, classes }) => {
  document.body.style.overflowX = "hidden";
  const { smallMenu, setSmallMenu, contRef } = useApp();

  return (
    <div className={classes} ref={contRef}>
      <Header setSmallMenu={setSmallMenu} smallMenu={smallMenu} page="home" />
      {children}
      <SmallMenu open={smallMenu} setOpen={setSmallMenu} />
    </div>
  );
};

export default Page;
