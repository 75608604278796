import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Whaleonomics from "../components/Whaleonomics";
import GetStarted from "../components/BuyNow";
import RoadMap from "../components/Roadmap";
import TopBanner from "../components/Main";
import Features from "../components/About";
import Footer from "../components/Footer";
import { Club } from "../components/Club";
import Page from "../components/Page";
import Team from "../components/Team";

import { Cinders } from "../components/backgrounds";

const HomePage = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      // window.scrollTo({top:0,left:0, behavior: "smooth"})
    }
  }, [location]);

  return (
    <Page classes="app">
      <TopBanner />
      <div style={{ position: "relative" }}>
        <Club />
        <Whaleonomics />
        <GetStarted />
        <Team />
        <Features />
        <Cinders />
        <Footer />
      </div>
    </Page>
  );
};

export default HomePage;
