import { Bottle } from "./Bottle";
import { WhiskyGlass } from "./WhiskyGlass";
import { B1, B2, B3 } from "./BottleDesigns";
import { FaXTwitter, FaTelegram } from "react-icons/fa6";

const Bar = ({ top = true }) => {
  return (
    <div style={{ width: "100%", position: "relative", zIndex: 10 }}>
      {top && (
        <>
          <BarTop />
          <div
            style={{
              height: 8,
              width: "120%",
              borderRadius: 2,
              marginLeft: "-10%",
              background: "#e6e4d8",
            }}
          />
        </>
      )}
      <div
        style={{
          padding: 42,
          background: "#363636",
          width: "calc(120% - 48px)",
          marginLeft: "calc(-10% + 24px)",
          boxShadow:
            "0 .5rem 1rem rgba(0,0,0,.35), inset 2px 2px 2px rgba(0,0,0,.15)",
        }}
      >
        <div className="d-flex justify-content-between">
          <div
            className="d-flex justify-content-center align-items-center p-4"
            style={{
              width: "100%",
              background: "#202020",
              boxShadow:
                "inset 2px 2px 4px rgba(0,0,0,.25), inset -2px -2px 4px rgba(0,0,0,.12)",
            }}
          >
            <p className="about-p neon-text-sm mb-0">
              With a heart as big as his bank account, Willy founded the Whales
              Club, dedicated to empowering others through knowledge and shared
              success. This club became a beacon for aspiring investors, a place
              where Willy’s mantra of “spread love, it’s the Brooklyn way”
              resonated with every member’s quest for financial freedom.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bar;

const BarTop = () => {
  return (
    <div
      style={{
        top: 0,
        left: "50%",
        width: "80%",
        position: "absolute",
        transform: "translate(-50%, -100%)",
      }}
      className="d-flex align-items-end justify-content-between"
    >
      <div className="d-flex align-items-end justify-content-between">
        <Bottle width={58} height={64} stemHeight={22} color="#523d3b">
          <B1 />
        </Bottle>
        <Bottle color="#2f2459" zIndex={2}>
          <B2 />
        </Bottle>
        <Bottle
          width={44}
          height={72}
          className=" "
          stemHeight={26}
          style={{ marginLeft: -5 }}
        >
          <B3 />
        </Bottle>
      </div>
      <div className="d-flex align-items-end justify-content-between">
        <WhiskyGlass />
        <WhiskyGlass />
        <WhiskyGlass />
        <WhiskyGlass />
      </div>
    </div>
  );
};

export const Bar2 = () => {
  return (
    <div style={{ width: "100%", position: "relative", zIndex: 10 }}>
      <BarTop />
      <div
        style={{
          height: 8,
          width: "120%",
          borderRadius: 2,
          marginLeft: "-10%",
          background: "#e6e4d8",
        }}
      />
      <div
        style={{
          padding: 24,
          background: "#363636",
          width: "calc(120% - 48px)",
          marginLeft: "calc(-10% + 24px)",
          boxShadow:
            "0 .5rem 1rem rgba(0,0,0,.35), inset 2px 2px 2px rgba(0,0,0,.15)",
        }}
      >
        <div className="d-flex justify-content-between">
          <div
            className="d-flex justify-content-center align-items-center p-4 flex-column"
            style={{
              width: "100%",
              background: "#202020",
              boxShadow:
                "inset 2px 2px 4px rgba(0,0,0,.25), inset -2px -2px 4px rgba(0,0,0,.12)",
            }}
          >
            <div className="marker text-center" style={{ fontSize: 24 }}>
              Discover the Whales Club on Telegram!
            </div>
            <div className="marker text-center mb-2">
              Join our 24/7 VC where we unite to explore crypto’s latest, share
              insights, and unlock alpha knowledge. It’s not just about trends;
              it’s about thriving together in the crypto world, inspired by
              Willy HODL’s smart hustle.
            </div>
            <div className="row marker" style={{ fontSize: 38 }}>
              <div className="jc-ac">
                <a
                  rel="noreferrer"
                  className="club-a"
                  target="_blank"
                  href="https://t.me/whalesclubcommunity"
                >
                  <FaTelegram />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
