import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { FaXTwitter, FaTelegram } from "react-icons/fa6";

const Footer = () => {
  const navigate = useNavigate();
  const date = new Date();
  const year = date.getFullYear();

  return (
    <div className="">
      <footer className="footer section-container">
        <div className="psr zi10">
          <div className="container">
            <div className="site-footer-four__upper-sep" />
            <div className="row">
              <div className="col-lg-4 npl">
                <div
                  className="footer-logo-a marker"
                  onClick={() => navigate("/")}
                >
                  $WHC
                </div>
                <div
                  className="marker"
                  style={{ textAlign: "center", color: "black" }}
                >
                  Catalouging the story, saga, and accomplishments of Willy
                  Whales: The Whales Club Legend
                </div>
              </div>
              <div className="col-lg-3 center-footer"></div>
              <div className="col-lg-5 npr">
                <div className="footer-widget">
                  <ul className="list-unstyled footer-lg-link-section">
                    <Link to="#about" className="footer-sub">
                      About
                    </Link>
                    <Link to="#whaleonomics" className="footer-sub">
                      Whaleonomics
                    </Link>
                    <a
                      href="https://solscan.io/token/9VLtWhS7Zope97RAJ1KBk2n8FHcNDu5QxqXWv1A3WMko"
                      className="footer-sub"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Contract
                    </a>
                    <a
                      href="https://jup.ag/swap/SOL-WHC_9VLtWhS7Zope97RAJ1KBk2n8FHcNDu5QxqXWv1A3WMko"
                      className="footer-sub"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Buy Now
                    </a>
                  </ul>
                  <div className="d-flex justify-content-between">
                    <div className="footer-sub2">Charts</div>
                    <div className="d-flex">
                      <a className="footer-icon" href={links?.dex}>
                        <img
                          alt="dex tools link"
                          src="/images/dex-logo.png"
                          style={{ width: 20 }}
                        />
                      </a>
                      <a className="footer-icon" href={links?.cg}>
                        <img
                          alt="dex tools link"
                          src="/images/cg-logo.png"
                          style={{ width: 20 }}
                        />
                      </a>
                      <a className="footer-icon" href={links?.birdeye}>
                        <img
                          alt="dex tools link"
                          src="/images/birdeye-logo.png"
                          style={{ width: 20 }}
                        />
                      </a>
                      <a className="footer-icon" href={links?.solscan}>
                        <img
                          alt="dex tools link"
                          src="/images/solscan-logo.png"
                          style={{ width: 20 }}
                        />
                      </a>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="footer-sub2">Follow Us</div>
                    <div className="d-flex">
                      <a className="footer-icon" href={links?.tg}>
                        <FaTelegram />
                      </a>
                      <a className="footer-icon" href={links?.x}>
                        <FaXTwitter />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="footer-btm psr zi10">
              <div className="container text-center">
                <div className="site-footer-four__bottom-sep" />
                <p style={{ color: "black" }}>
                  @ Copyright {year} WHC. All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;

const links = {
  tg: "https://t.me/whalesclubcommunity",
  x: "https://twitter.com/whalesclub_sol",
  cg: "https://www.coingecko.com/en/coins/whales-club",
  dex: "https://www.dextools.io/app/en/solana/pair-explorer/B8NWoMHhEu9sfXZ2VnUhjRzHRpH5guyVtdVfZr12QFmP?t=1711976920380",
  solscan:
    "https://solscan.io/token/9VLtWhS7Zope97RAJ1KBk2n8FHcNDu5QxqXWv1A3WMko",
  birdeye:
    "https://birdeye.so/token/9VLtWhS7Zope97RAJ1KBk2n8FHcNDu5QxqXWv1A3WMko?chain=solana",
};
