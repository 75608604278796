import React from "react";
import Highlight from "../basic/Highlight";

const About = () => {
  return (
    <section id="about">
      <NewNewAbout />
    </section>
  );
};

export default About;

const NewNewAbout = () => {
  return (
    <div className="container">
      <div className="d-flex align-items-center flex-column mb-3">
        <Highlight
          c1="#000"
          c2="#f5cb45"
          text="About $WHC"
          style={{ marginTop: 100 }}
        />
        <div style={{ color: "black" }} className="section-header">
          Willy's Story
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-lg-6 col-xl-4 mb-5">
          <Painting src="whc-about1.png" />
          <Plaque
            content="Introducing Willy Whales, an average whale from the tough streets,
              whose life was a battle against poverty and neglect. Born to
              Jamaican immigrants in a neighborhood where dreams often faded,
              Willy’s early life was a struggle for survival, overshadowed by
              the allure of fast money and fleeting fame."
          />
        </div>
        <div className="col-12 col-lg-6 col-xl-4 mb-5">
          <Painting src="whc-about2.png" />
          <Plaque
            content="In high school, Willy’s life hit a low note when his long-time
          girlfriend left him for someone wealthier, deepening his sense of
          failure. Laughed at and labeled a fool for dropping out, he felt his
          back against the wall, living a life with no direction, “considered a
          fool ‘cause I dropped out of high school.”"
          />
        </div>
        <div className="col-12 col-lg-6 col-xl-4 mb-5">
          <Painting src="whc-about3.png" />
          <Plaque
            content="During these dark times, alone in his room, Willy encountered
                the world of cryptocurrency. It was here, amidst the despair,
                that he saw a glimmer of hope, a chance to rewrite his story. He
                dove into crypto, learning the ropes, making savvy investments
                that soon paid off. His portfolio grew “from negative to
                positive,” turning him into a local legend, Willy HODL."
          />
        </div>
        <div className="col-12 col-lg-6 col-xl-4 mb-5">
          <Painting src="whc-about4.png" />
          <Plaque
            content="As his wealth surged, so did his reputation. From a “common thief to
            up close and personal with Robin Leach,” Willy’s transformation was
            nothing short of miraculous. He traded sardine dinners for champagne
            wishes, embodying the rags-to-riches tale he once thought
            unreachable."
          />
        </div>
        <div className="col-12 col-lg-6 col-xl-4 mb-5">
          <Painting src="whc-about5.png" />
          <Plaque
            content="Willy’s lavish new lifestyle, complete with designer threads,
                  luxury cars, and opulent homes, became the talk of the town.
                  Yet, despite the riches, he remained true to his roots, never
                  forgetting where he came from. He knew all too well that “Mo
                  Money, Mo Problems” could be the reality of sudden wealth."
          />
        </div>
        <div className="col-12 col-lg-6 col-xl-4 mb-5">
          <Painting src="whc-about6.png" />
          <Plaque
            content="With a heart as big as his bank account, Willy founded the Whales
            Club, dedicated to empowering others through knowledge and shared
            success. This club became a beacon for aspiring investors, a place
            where Willy’s mantra of “spread love, it’s the Brooklyn way”
            resonated with every member’s quest for financial freedom."
          />
        </div>
        <div className="col-12 col-lg-6 col-xl-4 mb-5">
          <Painting src="whc-about7.png" />
          <Plaque
            content="Through the Whales Club, Willy Whales became more than a wealthy
                magnate; he became a beacon of hope and a testament to the power
                of belief, perseverance, and the transformative magic of
                cryptocurrency."
          />
        </div>
      </div>
    </div>
  );
};

const Painting = ({ src }) => {
  return (
    <div
      className="shadow"
      style={{
        border: "16px solid white",
        position: "relative",
        width: "100%",
        zIndex: 2,
      }}
    >
      <img alt=" " style={{ width: "100%" }} src={`/images/${src}`} />
      <div
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          boxShadow:
            "inset 5px 5px 10px rgba(0,0,0,.5), inset -5px -5px 10px rgba(0,0,0,.25)",
        }}
      />
    </div>
  );
};

const Plaque = ({ content }) => {
  return (
    <div style={{ position: "relative" }}>
      <div
        className="shadow mt-3"
        style={{
          padding: 14,
          width: "100%",
          color: "black",
          lineHeight: 1.3,
          background: "#f8df9c",
          textAlign: "center",
        }}
      >
        <div
          className="p-3 marker"
          style={{
            width: "100%",
            height: "100%",
            background: "rgba(238,238,238,1)",
            boxShadow: "1px 1px 4px rgba(0,0,0,.3)",
          }}
        >
          {content}
        </div>
      </div>
      <Corner style={{ top: 0, left: 0, transform: "translate(-50%, -50%)" }} />
      <Corner style={{ top: 0, right: 0, transform: "translate(50%, -50%)" }} />
      <Corner
        style={{ bottom: 0, left: 0, transform: "translate(-50%, 50%)" }}
      />
      <Corner
        style={{ bottom: 0, right: 0, transform: "translate(50%, 50%)" }}
      />
    </div>
  );
};

const Corner = ({ style = {} }) => {
  return (
    <div
      style={{
        width: 24,
        height: 24,
        borderRadius: "50%",
        position: "absolute",
        background: "#727273",
        ...style,
      }}
    />
  );
};
