import React from "react";
import Highlight from "./basic/Highlight";

const Team = () => {
  return (
    <section className="section-container" id="team">
      <div
        className="d-flex align-items-center"
        style={{ flexDirection: "column" }}
      >
        <Highlight
          text="Community Driven"
          className="mt-5"
          c2="#f5cb45"
          c1="#000"
        />
        <div
          className="section-header"
          style={{ textShadow: "1px 1px 2px rgba(0,0,0,.4)" }}
        >
          $WHC Team
        </div>
      </div>
      <div
        className="d-flex align-items-center"
        style={{ flexDirection: "column" }}
      >
        <p
          style={{
            fontSize: 20,
            fontWeight: 600,
            maxWidth: 800,
            textAlign: "center",
            color: "black",
          }}
          className="marker"
        >
          It’s important to note that this is a community-driven initiative,
          representing a takeover from the old developer who has since left.
          Members are actively involved in shaping its direction and success.
        </p>
      </div>
    </section>
  );
};

export default Team;

// <img
//   src="/images/teamPlane.png"
//   alt="WHC team"
//   style={{ width: "90%", maxWidth: 1000 }}
// />
