import React from "react";
import { Bricks } from "./backgrounds";

const tags = [{ text: "$WHC 4 Life" }, { text: "Join the Whales Club" }];

//solana, WHC, 9VLtWhS7Zope97RAJ1KBk2n8FHcNDu5QxqXWv1A3WMko, price feed
const Banner = () => {
  return (
    <section className="banner">
      <div className="container" style={{ position: "relative" }}>
        <div className="col-12 col-lg-7 main-col">
          <div
            className="graf"
            style={{
              transform: "rotate(-8deg)",
              fontSize: 34,
            }}
          >
            F**k the Jeets
          </div>
          <div>
            <img
              alt=" "
              src="/images/title1.png"
              style={{ width: "100%", transform: "rotate(-2deg)" }}
              className="d-none d-lg-block"
            />
            <img
              alt=" "
              src="/images/title2.png"
              style={{ width: "100%", transform: "rotate(-2deg)" }}
              className="d-none d-lg-block"
            />
            <img
              alt=" "
              src="/images/titlesm.png"
              style={{ width: "100%" }}
              className="d-block d-lg-none"
            />
            <img
              alt=" "
              src="/images/title2sm.png"
              style={{ width: "100%" }}
              className="d-block d-lg-none"
            />
            <div className="graf mt-3 main-sub">
              Uniting Iconic Legends, Luxurious Lifestyles, and the Blockchain
              into One Crypto Symphony!
            </div>
          </div>

          <div
            className="graf"
            style={{
              transform: "rotate(2deg)",
              fontSize: 34,
            }}
          >
            $WHC 4 Lyfe
          </div>
        </div>
      </div>
      <Bricks />
      <div style={{ position: "relative" }}>
        <div className="boat-pos">
          <img alt="" src="/images/whc1.png" style={{ width: "100%" }} />
        </div>
      </div>
    </section>
  );
};

export default Banner;

//<div className="main-header">Big Willy Hodl's Whales Club</div>

// <div className="d-flex justify-content-center">
//   <a
//     style={{ width: 350, maxWidth: "60%" }}
//     href="https://solscan.io/token/9VLtWhS7Zope97RAJ1KBk2n8FHcNDu5QxqXWv1A3WMko"
//     rel="noreferrer"
//     target="_blank"
//   >
//     <img
//       alt=" "
//       src="/images/btnBg3.png"
//       style={{ width: "100%" }}
//     />
//   </a>
// </div>
