import { useNavigate } from "react-router-dom";
import { Bricks } from "../components/backgrounds";

const NotFound = () => {
  const height = window.innerHeight;
  const navigate = useNavigate();

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          position: "relative",
          zIndex: 10,
        }}
      >
        <img
          alt=" "
          src="/images/titlesm.png"
          style={{ width: "100%", maxWidth: 500 }}
        />
        <img
          alt=" "
          src="/images/title2sm.png"
          style={{ width: "100%", maxWidth: 500 }}
        />
        <div className="graf mt-3" style={{ fontSize: 32 }}>
          Somethings Missing
        </div>
        <div
          className="btn-1 hover-shadow hover-opacity mt-5"
          style={{ marginLeft: 0, background: "#f8df9c", color: "black" }}
          onClick={() => navigate("/")}
        >
          Go Home
        </div>
      </div>
      <Bricks />
    </div>
  );
};

export default NotFound;
